import axios from "axios"
import EventBus, { EVENTS } from "@/eventbus"
import i18n from "config/i18n"
import router from "config/router"
import store from "store/index"
import * as types from "store/types"

const ajax = axios.create({
    baseURL: `${process.env.VUE_APP_API_URL}/api/v1`,
    timeout: 15000 //15 seconds
})

//send cookie with request
//axios.defaults.withCredentials = true

ajax.interceptors.response.use(
    function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data

        const data = response.data
        if (!data) {
            return data
        }
        //trigger flash from response
        if (typeof data.flash !== "undefined" && data.flash !== "") {
            EventBus.emit(EVENTS.SHOW_FLASH, {
                title: i18n.global.t("flash.success"),
                message: data.flash,
                type: "success"
            })
        }

        return data
    },
    function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error

        let data = error.response ? error.response.data : null
        if (!data) {
            return Promise.reject(error)
        }

        if (error.response.status === 401) {
            //no more valid token, go to auth login
            router.push("/auth/logout")
            store.commit(types.SET_FINISHED_SESSION, true)
            //todo ? return Promise.reject(error)
        }

        //trigger flash from response
        if (typeof data.flash !== "undefined" && data.flash !== "") {
            EventBus.emit(EVENTS.SHOW_FLASH, {
                title: i18n.global.t("flash.warning"),
                message: data.flash,
                type: "warning"
            })
        }

        //init to object because 404
        if (error.response.status === 404) {
            data = {
                success: false
            }
        }

        //init to empty object by default if not provided
        if (typeof data.errors === "undefined") {
            data.errors = {}
        }

        return Promise.reject(data)
    }
)

export default ajax
